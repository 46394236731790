
import { Component, Vue } from 'vue-property-decorator';
import MenuModule from '@/store/modules/menu/MenuModule';
import SearchModule from '@/store/modules/search/SearchModule';
import { MenuItem } from '@/store/modules/menu/IMenuModule';
import PlusButton from './PlusButton.vue';
import UserWidget from './UserWidget.vue';
import Logo from '@/assets/images/logo.svg?inline';
import Icon from '@/components/ui/icon/Icon.vue';
import SettingsModule from '@/store/modules/settings/SettingsModule';
import GeneralModule from '@/store/modules/general/GeneralModule';

@Component({
  name: 'NavBar',
  components: {
    Logo,
    PlusButton,
    UserWidget,
    Icon,
  },
})
export default class NavBar extends Vue {
  public get navItems(): Array<MenuItem> {
    return MenuModule.mainMenu;
  }

  public get reducedMenu(): Array<MenuItem> {
    return MenuModule.reducedMenu;
  }

  public get settings()
  {
    return SettingsModule.getSettings
  }
  public get authCompleted()
  {
    return GeneralModule.authCompleted;
  }
  public section(sectionName: string)
  {
    return this.settings[this.settings.findIndex((el) => el.slug === sectionName)]
  }
  public get showReducedNav() {
    return true;
  }
  sectionAllow(sectionLink: string)
  { 
    if(sectionLink === '/documents' && !this.section('knowledge'))
    {
      return false
    }
    return true
  }

  get isAuthCompleted()
  {
    return GeneralModule.isAuthCompleted;
  }

  toggleSearch() {
    SearchModule.toggleSearchState();
  }
}
