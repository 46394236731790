
import ClassListComposer from '@/mixins/ClassListComposer';
import Colorizable from '@/mixins/Colorizable';
import { mixins } from 'vue-class-component';
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import ContextMenuSpawner from '../ContextMenuSpawner.vue';
import Icon from '@/components/ui/icon/Icon.vue';
// TODO: implement this through validation service
// eslint-disable-next-line no-useless-escape
const urlRegExp = new RegExp('/([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm');

@Component({
  components: {
    Icon,
  },
})
export default class ContextMenuLink extends mixins(Colorizable, ClassListComposer) {
  readonly baseClass = 'context-menu-link';

  @Prop() to: string;
  @Prop() iconName: string;
  @Prop({ default: false }) external: boolean;
  @Prop({ default: false }) active: boolean;
  /**
   * Using numeric values, you can specify the parents closing depth
   */
  @Prop({ default: false }) preventClose: boolean | number;

  get isRouterLink(): boolean {
    return !!this.to && !urlRegExp.test(this.to);
  }

  get closeableParents(): Array<ContextMenuSpawner> {
    let currentParent = this.$parent;
    const parents: Array<Vue> = [];

    while (currentParent) {
      parents.push(currentParent);
      currentParent = currentParent.$parent;
    }

    return parents.filter((parent: Vue & { close?(): unknown }) => {
      return typeof parent.close === 'function';
    }) as Array<ContextMenuSpawner>;
  }

  @Emit('click')
  onClick(e: Event) {
    if (this.$parent instanceof ContextMenuSpawner) return e;
    if (this.preventClose === true) return e;

    this.closeableParents.forEach((parent, index) => {
      if (this.preventClose === false) {
        parent.close();
      } else if (index < this.preventClose) {
        parent.close();
      }
    });

    return e;
  }
}
