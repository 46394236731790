import axios from 'axios';
import { MAIN_API_HOST } from '@/services/helpers/constants';
import { KnowledgeFromRequest } from '@/typings/domain';
import KnowledgeResponseData from '@/models/dto/responses/knowledgeResponse'
import { HEADERS } from '@/services/helpers/constants';
import Cookies from 'js-cookie';
import qs from 'qs';

const getKnowles = async (filteredData?: any): Promise<KnowledgeFromRequest> => {
const payload = qs.stringify(filteredData);

return axios
  .get(`${MAIN_API_HOST}/knowledge?${payload}`, {
    headers: { Authorization: `Bearer ${Cookies.get(HEADERS.AUTH_HEADER)}` },
  })
  .then(result => {
    return result.data;
  })
  .catch(error => {
    return console.log(error);
  })
};
const getKnowledge = async (id:string): Promise<KnowledgeResponseData> =>
axios
  .get(`${MAIN_API_HOST}/knowledge/${id}`, {
    headers: { Authorization: `Bearer ${Cookies.get(HEADERS.AUTH_HEADER)}` },
  })
  .then(result => {
    return result.data;
  })
  .catch(error => {
    return console.log(error);
  });

const getSpecList = async (id:number) =>
  axios
    .get(`${MAIN_API_HOST}/knowledge/getSpecialisations/${id}`)
      .then(result => result.data.success)
      .catch(error => console.error(error));

export default getKnowles;
export { getKnowledge, getSpecList } ;
