
import { Component, Vue, Prop, Watch, Emit, Ref } from 'vue-property-decorator';
import ContextMenu from '@/components/context-menu/ContextMenu.vue';

@Component({
  name: 'Dropdown',
  components: {
    ContextMenu,
  },
})
export default class Dropdown extends Vue {
  @Ref('toggler') readonly toggler!: HTMLElement;

  @Prop({ default: false }) left: boolean;
  @Prop({ default: false }) top: boolean;
  @Prop({ default: false }) inline: boolean;

  public isExpanded = false;
  public preventClickOutsideDetection = false;

  public close() {
    this.isExpanded = false;
  }

  public toggle(e: Event): boolean {
    e.stopPropagation();

    this.isExpanded = !this.isExpanded;

    return this.isExpanded;
  }

  public onClickOutside(e: Event) {
    const path = e.composedPath();
    const index = path.indexOf(this.toggler);

    const target = e.target as Element;
    const cm = 'context-menu';
    const cm_node = target.closest(`.${cm}`)?.className;

    if (cm_node === cm || index !== -1) return;

    this.isExpanded = false;
  }

  @Watch('isExpanded')
  @Emit('change')
  onChange(value: boolean) {
    return value;
  }
}
