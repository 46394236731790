
import { Component, Mixins } from 'vue-property-decorator';
import { ExpandableMixin } from '@/mixins/ExpandableMixin';
import Dropdown from '@/components/ui/dropdown/Dropdown.vue';
import MenuModule from '@/store/modules/menu/MenuModule';
import GeneralModule from '@/store/modules/general/GeneralModule';
import { IContextMenuItem, ContextMenuItemType } from '@/components/context-menu';
import ContextMenuHeader from '@/components/context-menu/items/ContextMenuHeader.vue';
import ContextMenuLink from '../context-menu/items/ContextMenuLink.vue';
import UsersModule from '@/store/modules/users/UsersModule';
import SpecializationsModule from '@/store/modules/specializations/SpecializationsModule';
import TeamsModule from '@/store/modules/teams/TeamsModule';
import KnowledgeModule from '@/store/modules/knowledge/KnowledgeModule';
import RolesModule from '@/store/modules/roles/RolesModule';

@Component({
	components:
	{
		Dropdown,
		ContextMenuHeader,
		ContextMenuLink,
	},
})
export default class UserWidget extends Mixins(ExpandableMixin)
{
	get userId()
	{
		return UsersModule.currentUserId;
	}
	get user()
	{
		return UsersModule.currentUser
	}
	get fullName()
	{
		return `${UsersModule.currentUser.first_name} ${UsersModule.currentUser.last_name}`;
	}
	public get authCompleted()
	{
		return GeneralModule.authCompleted;
	}
	get dropdownItems(): Array<IContextMenuItem>
	{
		return MenuModule.widgetUserMenu.map((item: IContextMenuItem) =>
		{
			if (item.type === ContextMenuItemType.header)
			{
				item.text = `${UsersModule.currentUser?.firstName} ${UsersModule.currentUser?.lastName}`;
			}

			return item;
		});
	}

	logout()
	{
		SpecializationsModule.clearSpecialisations();
		TeamsModule.clearTeams();
		RolesModule.clearRoles();
		KnowledgeModule.clearKnoledges();
		GeneralModule.logout();
	}
}
