import { Route } from 'vue-router';
import KnowledgeModule from '@/store/modules/knowledge/KnowledgeModule';
import Notify from '@/services/helpers/notify';


async function shouldBeAccount(to: Route, from: Route, next: Function) {
  KnowledgeModule.fetchKnowledges('')
		.then((res) => {
			const knowledges = JSON.parse(JSON.stringify(res.data.data));
      if (knowledges.roleBlock?.length)
        return next({path: `/documents/dashboard/${knowledges.roleBlock[0].id}`})
      else 
        return next({path: '/documents/dashboard'})
		})
		.catch((e) => { Notify.error('Что-то пошло не так, попробуйте позже.') })
}
export default shouldBeAccount;