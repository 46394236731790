import getAllRoles from '@/api/roles/actions/getAllRoles.action';
import getSingleRole from '@/api/roles/actions/getSingleRole';
import createRole from '@/api/roles/actions/createRole.action';
import createSubRole from '@/api/roles/actions/createSubRole.action';
import deleteSubRole from '@/api/roles/actions/deleteSubRole.action';
import deleteRole from '@/api/roles/actions/deleteRole.action';
import editRole from '@/api/roles/actions/editRole.action';

const roles = {
  getAllRoles,
  getSingleRole,
  createRole,
  createSubRole,
  deleteSubRole,
  editRole,
  deleteRole,
};

export default roles;
