
import { Component, Vue } from 'vue-property-decorator';
import Logo from '@/assets/images/logo-mobile.svg?inline';
import SearchBarMobile from '../app-bar/SearchBarMobile.vue';
import MenuModule from '@/store/modules/menu/MenuModule';
import { MenuItem } from '@/store/modules/menu/IMenuModule';
import Icon from '../ui/icon/Icon.vue';
import UsersModule from '@/store/modules/users/UsersModule';

@Component({
	name: 'NavBarMobile',
	components:
	{
		Logo,
		SearchBarMobile,
		Icon,
	},
})
export default class NavBarMobile extends Vue
{
	public expanded = false;
	public showMenu = false;
	public showSearchResults = false;
	public showUserMenu = false;

	public mainMenuItems: Array<MenuItem>;
	public userMenuItems: Array<MenuItem>;

	public get reducedMenu(): Array<MenuItem>
	{
		return MenuModule.reducedMenu;
	}

	mounted()
	{
		this.mainMenuItems = MenuModule.mainMenu;
		this.userMenuItems = MenuModule.userMenu;
	}

	get fullName()
	{
		return `${UsersModule.currentUser.first_name} ${UsersModule.currentUser.last_name}`;
	}

	get mainMenu(): Array<MenuItem>
	{
		return this.mainMenuItems;
	}

	get userMenu(): Array<MenuItem>
	{
		return this.userMenuItems;
	}

	public onSearchChange(value: boolean): void
	{
		this.showSearchResults = value;
	}

	public toggleMenu(): void
	{
		if (this.showUserMenu)
		{
			this.showUserMenu = false;
			this.expanded = false;
			return;
		}

		if (this.showMenu)
		{
			this.showMenu = false;
		} else if (!this.showUserMenu)
		{
			this.showMenu = true;
		}

		this.expanded = this.showMenu || this.showUserMenu;
	}

	public toggleUserMenu(): void
	{
		this.showUserMenu = !this.showUserMenu;
		this.expanded = this.showUserMenu;
		if (this.showMenu) this.showMenu = false;
	}
}
