enum StoreModules {
  General = 'general',
  Menu = 'menu',
  Roles = 'roles',
  Search = 'search',
  Users = 'users',
  Projects = 'projects',
  Pin = 'pin',
  Knowledge = 'knowledge',
  Categories = 'categories',
  SubCategories = 'subCategories',
  Tags = 'tags',
  TagsGroups = 'tagsGroups',
  Teams = 'teams',
  Settings = 'settings',
  Mode = 'mode',
}

export default StoreModules;
