
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Icon extends Vue {
  @Prop({ default: '1em' }) size: string;
  @Prop() name: string;
  public svg = '';

  created(): void {
    this.load();
  }

  private async load(): Promise<void> {
    const { default: svg } = await import(`!raw-loader!icon-loader!@/assets/images/icons/${this.name}.svg`);
    this.svg = svg.replace('%width%', this.size).replace('%height%', this.size);
  }
}
