
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/ui/dropdown/Dropdown.vue';
import PlusButtonIcon from '@/assets/images/plus-button.svg?inline';
import PlusButtonBigIcon from '@/assets/images/plus-button-big.svg?inline';
import ContextMenuLink from '../context-menu/items/ContextMenuLink.vue';
import UsersModule from '@/store/modules/users/UsersModule';
import TeamsModule from '@/store/modules/teams/TeamsModule';

@Component({
  name: 'PlusButton',
  components: {
    PlusButtonIcon,
    PlusButtonBigIcon,
    Dropdown,
    ContextMenuLink,
  },
})
export default class PlusButton extends Vue {
  @Prop({ default: false }) floating: boolean;
  public expanded = false;

  get curentUsers()
  {
    return UsersModule.currentUser;
  }
  canManage(sectionName: string)
  {
    if(sectionName === 'knowledge')
    {
      return (
        !UsersModule.currentUser.admin &&
        UsersModule.currentUser.permitions.knowledge.manage) ||
        UsersModule.currentUser.admin || 
        (UsersModule.currentUser.admin && 
        !UsersModule.currentUser.permitions.knowledge.manage)
    }
    // if(sectionName === 'projects')
    // {

    // }
    if(sectionName === 'team')
    {
      return this.curentUsers.permitions.team.invite || this.curentUsers.admin;
    }
  }

  get knowledgePermission()
  {    
    return TeamsModule.permissionStatus
  }

}
