
import { Component, Emit, Mixins } from 'vue-property-decorator';
import { SearchbarMixin } from '@/mixins/SearchbarMixin';
import Icon from '@/components/ui/icon/Icon.vue';

@Component({
  components: {
    Icon,
  },
})
export default class SearchBarMobile extends Mixins(SearchbarMixin) {
  @Emit('change')
  public onInput(): boolean {
    this.showResults = !!this.query;
    return this.showResults;
  }

  @Emit('change')
  public beforeDestroy(): boolean {
    return false;
  }

  public clearInput(): void {
    this.query = '';
    this.onInput();
  }
}
