import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators';
import {
    ISpecialisationsModule,
    SpecialisationsModuleActions as Actions,
} from '@/store/modules/specializations/ISpecializationsModule';
import { Specialisations, SubRole } from '@/typings/domain';

import ApiClient from '@/api/api.client';
import store from '@/store';
import StoreModules from '@/store/StoreModules';
import RolesModule from '../roles/RolesModule';

@Module({
    name: 'SpecialisationsModule',
    dynamic: true,
    namespaced: true,
    store,
})
class SpecialisationsModule extends VuexModule implements ISpecialisationsModule {
    specialisations: Specialisations[] = [];
    constructor(props: unknown) {
        super(props);
    }

    
    @Mutation 
    public setSpecialisations(specialisations: Array<Specialisations>)
    {
        this.specialisations = specialisations
    }

    @Mutation
    public clearSpecialisations()
    {
        this.specialisations = [];
    }

    @Mutation
    public updateSpecialisationsValue({name, specsId}: {name: string, specsId: number})
    {
        const index = RolesModule.specialisations.findIndex((el) => 
        {
            return el.id === specsId;   
        });
        RolesModule.specialisations[index].name = name
    }

    @Action
    async [Actions.updateSpecialisations]({name, specsId, is_admin}: {name?: string, specsId: number, is_admin?: boolean}) {
        await ApiClient.specialisations
            .updateSpecialisations(specsId, name, is_admin)
            .then(data => { this.updateSpecialisationsValue({name:data.name, specsId: data.id}) })
    }

    @Action
    async [Actions.fetchSpecialisations](params?: object) {
        await ApiClient.specialisations
            .getAllSpecialisations(params)
            .then(specialisationsFromServer => {
                this.setSpecialisations(specialisationsFromServer)
            })
    }
}

    export default getModule(SpecialisationsModule);
