import createSubCategories from '@/api/subcategories/actions/createSubcategories.action';
import getSubCategories from '@/api/subcategories/actions/getSubCategories.action';
import getSubCategoriesById from '@/api/subcategories/actions/getSubCategoriesById.action';
import deleteSubcategory from '@/api/subcategories/actions/deleteSubcategory.action';
import groupSubcategory from '@/api/subcategories/actions/groupSubcategory.action';

const subCategories = {
    createSubCategories,
    getSubCategories,
    getSubCategoriesById,
    deleteSubcategory,
    groupSubcategory,
};

export default subCategories;
