
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({
  name: 'ContextMenu',
})
export default class ContextMenu extends Vue {
  @Prop({ default: false }) hidden: boolean;
  @Prop({ default: false }) mobile: boolean;
  @Prop({ default: false }) noBorder: boolean;

  @Emit('close')
  close(e?: Event): Event {
    return e;
  }
}
