import createKnowledge from '@/api/knowledge/actions/create.action';
import updateKnowledge from '@/api/knowledge/actions/update.action';
import getKnowles from '@/api/knowledge/actions/get.action';
import { getKnowledge, getSpecList } from '@/api/knowledge/actions/get.action';
import deleteKnowledge from '@/api/knowledge/actions/delete.action';
import destroyMany from '@/api/knowledge/actions/destroyMany.action';
import setAsViewed from '@/api/knowledge/actions/setAsViewed.action';
import getKnowledges from '@/api/knowledge/actions/getKnowledges';
import setProjectSettings from '@/api/knowledge/actions/setKnowledgeSettings.action'

const knowledge = {
  createKnowledge,
  updateKnowledge,
  getKnowles,
  getKnowledge,
  getSpecList,
  deleteKnowledge,
  setAsViewed,
  setProjectSettings,
  getKnowledges,
  destroyMany,
};

export default knowledge;
